<template>
  <div>
    <v-row dense>
      <v-col
        cols="12"
        v-for="(item, i) in items"
        :key="`legend-item-${i}`"
      >
        <pibot-risk-chip
          :color="item.color"
          :risk="item.value"
          small
        /> <small v-text="item.description" />
      </v-col>
    </v-row>
  </div>
</template>

<script>
export default {
  name: 'pibot-eprs-severity-legend',
  components: {
    'pibot-risk-chip': () => import('@/components/chips/RiskChip')
  },
  data: () => ({
    items: [
      { color: 'success darken-2', value: '1', description: 'No further action required.' },
      { color: 'success darken-2', value: '2', description: 'Possibly investigate during next survey.' },
      { color: 'warning', value: '3', description: 'Recommended to investigate.' },
      { color: 'warning darken-1', value: '4', description: 'Highly recommended to investigate and review operating pressure (and possibly lower).' },
      { color: 'error darken-1', value: '5', description: 'Immediate action and investigation required. Review operating pressure (and possibly lower and or shutdown).' }
    ]
  })
}
</script>
